import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cli',
  templateUrl: './cli.component.html',
  styleUrls: ['./cli.component.scss']
})
export class CliComponent implements OnInit {

  namesAndUrls: Map<string, string>[] = [
    new Map<string, string>().set('name', 'CLI Part 2').set('url', '').set('partNum', '2'),
    new Map<string, string>().set('name', 'CLI Part 3').set('url', '').set('partNum', '3')
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }
}
