<main class="river_background">
  <article>
    <header class="title" aria-label="Page Title">
      <h2>About Me</h2>
    </header>
    <section>
      <header aria-label="Section Title">
        <h3>School</h3>
      </header>

      <p>
        I graduated from Western Carolina University on May 15 2021 with a Bachelor of Computer
        Science and a Minor in Mathematics. I graduated with a 3.97 GPA and was chosen to receive
        the Dean's Outstanding Scholar in Computer Science award for my graduating class.
      </p>
    </section>

    <section>
      <header aria-label="Section Title">
        <h3>Current Employment</h3>
      </header>

      <p>
        I am an Associate Software Engineer at Aquent, a workforce solutions company. I am very
        excited to be here as it is an excellent organization to work for and I am elated to be a
        Junior level Software Engineer because that is where it all begins. I have initialized the
        career of my dreams and am on my way up the ladder!
      </p>
    </section>
  </article>
</main>
