import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-operating-system',
  templateUrl: './operating-system.component.html',
  styleUrls: ['./operating-system.component.scss']
})
export class OperatingSystemComponent implements OnInit {

  namesAndUrls: Map<string, string>[] = [
    new Map<string, string>().set('name', 'OS Part 2').set('url', '/operating-system-p2').set('partNum', '2'),
    new Map<string, string>().set('name', 'OS Part 3').set('url', '/operating-system-p3').set('partNum', '3')
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }
}
