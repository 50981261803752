import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-operating-system-p2',
  templateUrl: './operating-system-p2.component.html',
  styleUrls: ['./operating-system-p2.component.scss']
})
export class OperatingSystemP2Component implements OnInit {

  namesAndUrls: Map<string, string>[] = [
    new Map<string, string>().set('name', 'OS Part 1').set('url', '/operating-system').set('partNum', '1'),
    new Map<string, string>().set('name', 'OS Part 3').set('url', '/operating-system-p3').set('partNum', '3')
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }
}
