  <nav>
    <ul>
      @for (nameAndUrl of namesAndUrls; track nameAndUrl.get('name')) {
        <li>
          <a id="nameAndUrl.get('name')"
           tabindex="0"
           (keyup.enter)="navToUrl(nameAndUrl.get('url'), nameAndUrl.get('partNum'))"
           (click)="navToUrl(nameAndUrl.get('url'), nameAndUrl.get('partNum'))">
             {{ nameAndUrl.get('name') }}
          </a>
        </li>
      }
    </ul>
  </nav>
