import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import Swal from "sweetalert2";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() namesAndUrls: Map<string, string>[] = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navToUrl(url: string | undefined, partNum: string | undefined) {
    let partNumber = 0;
    if (!url && (partNumber = parseInt(partNum ? partNum : ''))) {
      this.comingSoon(partNumber);
    } else {
      this.router.navigateByUrl(url ? url : 'No URL provided!')
      .catch(error => {
        console.error(!url ? `Error! No URL provided` : `Error! Invalid URL: ${url}`);
      });
    }
  }

  comingSoon(part: number) {
    Swal.fire({
      backdrop: true,
      background: '#333',
      confirmButtonColor: 'rgb(33, 100, 116)',
      title: '<h2>More Coming Soon!</h2>',
      confirmButtonText: 'Ok',
      width: 500,
      position: 'center',
      html: `<h3>I will add part ${part} eventually. For now I am focusing on getting my
             career started. I am keeping the home page updated with what I am currently
             getting into.</h3>`,
      heightAuto: false,
      padding: 0,
      imageUrl: '../../../assets/images/linux-256.png',
      imageHeight: 200,
    });
  }

}
