import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bash',
  templateUrl: './bash.component.html',
  styleUrls: ['./bash.component.scss']
})
export class BashComponent implements OnInit {

  namesAndUrls: Map<string, string>[] = [
    new Map<string, string>().set('name', 'Bash Part 2').set('url', '').set('partNum', '2'),
    new Map<string, string>().set('name', 'Bash Part 3').set('url', '').set('partNum', '3')
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }

  navCLI() {
    this.router.navigateByUrl('/cli');
  }
}
