<header class="mainNavigation" aria-label="Site Navigation Bar">
  <nav class="container">
    <a class="home" id="home" tabindex="1" (keyup.enter)="navigateHome()" autofocus>Home</a>
    <nav>
      <ul>
        <li>
          <button class="about" id="about" tabindex="2" (keyup.enter)="navigateAbout()">
            About
          </button>
        </li>
        <li>
          <div class="drop_div">
            <button class="hover_button" id="menu" tabindex="3" (keyup.enter)="toggleMenu()">
              🌎 Menu
            </button>
            <nav class="hover_drop_list hide_until" id="dropMenu" aria-label="Drop Down Menu">
              <a id="cli" tabindex="4" (keyup.enter)="navigateCLI()">CLI</a>
              <a id="bash" tabindex="5" (keyup.enter)="navigateBash()">Bash</a>
              <!-- <a id="rust" tabindex="6" (keyup.enter)="navigateRust()">Rust</a> -->
              <a id="os" tabindex="7" (keyup.enter)="navigateOS()">OS</a>
              <!-- <a id="arch" tabindex="8" (keyup.enter)="navArchInstall()">Arch Install</a> -->
              <!-- <a id="hslColorPicker" tabindex="9" (keyup.enter)="navHslPicker()">HSL Picker</a> -->
            </nav>
          </div>
        </li>
      </ul>
    </nav>
  </nav>
</header>

<router-outlet></router-outlet>
